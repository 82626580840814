import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  Inject,
  LOCALE_ID,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { formatDate } from "@angular/common";
import { openItemDisplayStatus } from "../../../finance/finance-constant";
import {
  ManualPaymentEntity,
  OpenItemEntity,
  RefundPaymentEntity,
  SearchCriteria,
} from "./models/open-item-list.model";
import { common } from "../../../framework/utils/common";
import { warningZoneArgs } from "../../new-product/home/warning-zone/warning-zone.component";
import { Day } from "@progress/kendo-date-math";
import { OpenItemListService } from "src/app/services/open-item-list.service";
import { OpenItemListingEntity } from "./OpenItemListingEntity";
import { Identity } from "src/app/shared/user/identity";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { OpenItemStatus } from "src/app/finance/finance-enums";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { MdInformationMessageComponent } from "../../shared/partials/md-information-message/md-information-message.component";
import { ViewEFTDetailsComponent } from "./view-EFT-details/view-EFT-details.component";
import { ViewPaynowDetailsComponent } from "./view-paynow-details/view-paynow-details.component";

@Component({
  selector: "app-open-item-list",
  templateUrl: "./open-item-list.component.html",
  styleUrls: ["./open-item-list.component.scss"],
})
export class OpenItemListComponent implements OnInit {
  REFUND_DETAILS: string = "REFUND DETAILS";

  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;

  @ViewChild("oiEffectiveDateTo", { static: false })
  public oiEffectiveDateTo!: DatePickerComponent;

  public openItemEntryDate_min!: Date;
  public openItemEntryDate_max!: Date;

  @ViewChild("oiPolicyEffectiveDateTo", { static: false })
  public oiPolicyEffectiveDateTo!: DatePickerComponent;

  public policyeffectiveDate_min!: Date;
  public policyeffectiveDate_max!: Date;

  @ViewChild("autoDebitPeriod_dateInput", { static: false })
  public autoDebitPeriod_dateInput!: DatePickerComponent;

  @ViewChild("appendTo", { read: ViewContainerRef, static: false })
  public appendTo!: ViewContainerRef;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  private listing: OpenItemListingEntity[] = [];
  private totalListing: number = 0;
  submitted = false;

  searchCriteria = new SearchCriteria();
  public statusList!: Array<{ text: string; value: number }>;
  private payableStatusList!: Array<number>;
  private refundableStatusList!: Array<number>;
  private refundableReviewStatusList!: Array<number>;
  private refundedViaPaynowOrEFTStatusList: Array<number> = [23, 27];

  private refundOpenItemID: string = "";
  private isCitiProduct: boolean = false;
  autoDebitPeriod: any;
  public segmentList!: Array<{ text: string; value: string }>;
  public userProductList!: any;
  public checkableRows: number[] = [];
  public selectedRowIndexes: number[] = [];
  public loading!: boolean;
  public showDetails: boolean = false;
  public openItemId!: string;
  public redirectOpened: boolean = false;
  public doManualPay: boolean = false;
  public doRefundPay: boolean = false;
  public doRefundEFT: boolean = false;
  public doBounceCheque: boolean = false;
  openItem!: OpenItemEntity | undefined;

  @Output() warningZoneEvent = new EventEmitter<any>();
  public disabledDates: Day[] = [
    Day.Saturday,
    Day.Sunday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
  ];
  // calenderValidation =
  //   "k-button-icon k-icon k-i-calendar k-link k-button k-nav-fast k-button-md k-rounded-md k-button-flat k-button-flat-base k-calendar-title k-disabled" +
  //   "k-empty k-calendar-td ng-star-inserted k-calendar-header k-hstack k-flex k-content k-scrollable k-calendar-th ng-star-inserted k-vstack k-calendar-view k-calendar-monthview ng-star-inserted k-calendar-caption";
  // @HostListener("document:click", ["$event"])
  // clickout(event: any) {
  //   let el: string = event.target.className;
  //   if (this.calenderValidation.includes(el)) {
  //     return;
  //   } else {
  //     if (this.oiEffectiveDateTo?.isOpen) {
  //       this.oiEffectiveDateTo.toggle(false);
  //     }
  //     if (this.oiPolicyEffectiveDateTo?.isOpen) {
  //       this.oiPolicyEffectiveDateTo.toggle(false);
  //     }
  //   }
  //   return;
  // }
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private openItemListService: OpenItemListService,
    private identity: Identity,
    private excelTemplate: ExcelTemplateService,
    private cdkDialogService: CdkDialogService
  ) {}
  ngOnInit(): void {
    this.defaultSearchCriteria();
    this.readProductRadio();
    this.fetchUserList();
    this.fetchStatusList();
    this.initStatusLists();
    this.setDbsAutoDebitPeriod();
    this.refresh();
  }
  private initStatusLists(): void {
    // PendingAutoDebit = 1
    // PendingInvoiceToBank = 2
    // PendingToInvoiceToIndividual = 3
    // AutoDebitted = 5
    // InvoicedToBank = 7
    // InvoicedToIndividual = 8
    // PendingManualPayByCheque = 11 (Cheque only)
    // UnpaidChequeToBank = 14
    // UnpaidChequeToIndividual = 15
    this.payableStatusList = [2, 3, 7, 8, 11, 14, 15];

    // PendingRefundToBank = 4
    // PendingRefundToIndividual = 13
    this.refundableStatusList = [4, 13];
    this.refundableReviewStatusList = [21, 22, 25];
  }
  private readProductRadio() {
    this.isCitiProduct = this.searchCriteria.productRadio == "citi";
  }
  private defaultSearchCriteria() {
    // segment dropdownlist options
    const segmentList = [
      { text: "CBG", value: "CBG" },
      { text: "IBG", value: "IBG" },
      { text: "PB", value: "PB" },
      { text: "TPC", value: "TPC" },
    ];
    this.segmentList = segmentList;
  }

  private fetchUserList() {
    // get user productsList
    if (this.isCitiProduct) {
      this.userProductList = this.identity
        .currentUser()
        .products?.filter(function (e) {
          let ucvalue = e.pdid.toString().toUpperCase();
          return (
            ucvalue == "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            ucvalue == "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            ucvalue == "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        });
    } else {
      this.userProductList = this.identity
        .currentUser()
        .products?.filter(function (e) {
          let ucvalue = e.pdid.toString().toUpperCase();
          return !(
            ucvalue == "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            ucvalue == "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            ucvalue == "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        });
    }
  }

  private fetchStatusList() {
    // get statusList
    this.statusList = [];

    for (const key in openItemDisplayStatus) {
      if (openItemDisplayStatus.hasOwnProperty(key)) {
        const value = openItemDisplayStatus[key];

        if (this.isCitiProduct) {
          if (
            this.searchCriteria.productRadio != null &&
            [1, 5, 6, 9, 10, 13].includes(Number(key))
          ) {
            this.statusList.push({ text: value, value: Number(key) });
          }
        } else this.statusList.push({ text: value, value: Number(key) });
      }
    }
    // sort statusList by asc
    this.statusList.sort((x, y) => {
      return x.text > y.text ? 1 : x.text < y.text ? -1 : 0;
    });
  }
  public on_quoteNumber_keyup(event: any): void {
    var k;
    k = event.data?.charCodeAt(); //         k = event.keyCode;  (Both can be used)
    if (
      !(
        (k > 64 && k < 91) ||
        (k > 96 && k < 123) ||
        k == 8 ||
        k == 45 ||
        k == 32 ||
        (k >= 48 && k <= 57)
      )
    ) {
      event.preventDefault();
    }
  }

  public getTitle(): string {
    return this.searchCriteria.productRadio === "dbs"
      ? "Total Premium"
      : "Net Premium";
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }
  public gridSelectionChange(selection: SelectionEvent) {
    console.log("gridSelectionChanged");
    const selectedData = selection?.selectedRows;
    if (selectedData != undefined && selectedData.length > 0) {
      var entity = selectedData[0].dataItem;
    }

    const deselectedData = selection?.deselectedRows;
    if (deselectedData != undefined && deselectedData.length > 0) {
      var entity = deselectedData[0].dataItem;
      //auto, from two-way binding of [(selectedKeys)]
      // this.selectedRowIndexes.forEach((item, index) => {
      //   if (item === deselectedData[0].index)
      //     this.selectedRowIndexes.splice(index, 1);
      // });
    }
  }

  public onChange_ProductRadio(): void {
    this.resetFormValues();
    this.readProductRadio();
    this.fetchUserList();
    this.fetchStatusList();

    if (this.isCitiProduct) {
      this.setCitiAutoDebitPeriod();
    } else {
      this.setDbsAutoDebitPeriod();
    }
    this.refresh();
  }
  private resetFormValues(): void {
    this.form.controls["policyNo"].reset();
    this.form.controls["invoiceNo"]?.reset();
    this.form.controls["chequeNo"].reset();
    this.form.controls["segments"]?.reset();
    this.form.controls["productIds"].reset();
    this.form.controls["statusIds"].reset();
    this.form.controls["EffectiveDateFrom"]?.reset();
    this.form.controls["EffectiveDateTo"]?.reset();
    if (this.oiEffectiveDateTo.isOpen) {
      this.oiEffectiveDateTo.toggle(false);
    }
  }
  private setCitiAutoDebitPeriod(): void {
    var d = new Date();
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    var autoDebitDefaultWeek;
    autoDebitDefaultWeek = formatDate(
      new Date(d.setDate(diff)),
      "dd/MM/yyyy",
      this.locale
    ).toString();
    this.searchCriteria.autoDebitPeriodYear =
      autoDebitDefaultWeek.split("/")[2];
    this.searchCriteria.autoDebitPeriodMonth =
      autoDebitDefaultWeek.split("/")[1];
    this.searchCriteria.autoDebitPeriodDate =
      autoDebitDefaultWeek.split("/")[0];
    this.searchCriteria.autoDebitPeriod = autoDebitDefaultWeek;

    this.autoDebitPeriod = new Date(
      parseInt(this.searchCriteria.autoDebitPeriodYear),
      parseInt(this.searchCriteria.autoDebitPeriodMonth) - 1,
      parseInt(this.searchCriteria.autoDebitPeriodDate)
    );
  }
  private setDbsAutoDebitPeriod(): void {
    var autoDebitDefaultMonth;
    autoDebitDefaultMonth = formatDate(
      new Date(),
      "MM/yyyy",
      this.locale
    ).toString();
    this.searchCriteria.autoDebitPeriodYear =
      autoDebitDefaultMonth.split("/")[1];
    this.searchCriteria.autoDebitPeriodMonth =
      autoDebitDefaultMonth.split("/")[0];
    this.searchCriteria.autoDebitPeriod = autoDebitDefaultMonth;
    this.autoDebitPeriod = new Date(
      parseInt(this.searchCriteria.autoDebitPeriodYear),
      parseInt(this.searchCriteria.autoDebitPeriodMonth) - 1
    );
    //this.autoDebitPeriod = autoDebitDefaultMonth;
    this.searchCriteria.autoDebitPeriodDate = "";
  }
  public onSubmit(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }

    //this.checkDatesRange();

    const isValid = this.validateMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }

  private checkDatesRange() {
    if (
      this.searchCriteria.effectiveDateFrom !== null &&
      this.searchCriteria.effectiveDateTo === null
    ) {
      this.searchCriteria.effectiveDateTo =
        this.searchCriteria.effectiveDateFrom;
    }
    if (
      this.searchCriteria.policyeffectiveDateFrom !== null &&
      this.searchCriteria.policyeffectiveDateTo === null
    ) {
      this.searchCriteria.policyeffectiveDateTo =
        this.searchCriteria.policyeffectiveDateFrom;
    }
  }

  private validateMandatoryFields(): boolean {
    let isValid: boolean = false;
    this.reformatPeriod();

    // dates
    const dates: boolean =
      this.searchCriteria.effectiveDateFrom !== null ||
      this.searchCriteria.effectiveDateTo !== null ||
      this.searchCriteria.policyeffectiveDateFrom !== null ||
      this.searchCriteria.policyeffectiveDateFrom !== null;

    isValid = dates; // || etc...

    return isValid;
  }
  reformatPeriod = () => {
    this.searchCriteria.autoDebitPeriod = "";
    if (!common.isUndefinedOrNull(this.autoDebitPeriod)) {
      if (this.isCitiProduct) {
        const period = formatDate(
          this.autoDebitPeriod,
          "dd/MM/yyyy",
          this.locale
        );
        this.searchCriteria.autoDebitPeriodYear = period.split("/")[2];
        this.searchCriteria.autoDebitPeriodMonth = period.split("/")[1];
        this.searchCriteria.autoDebitPeriodDate = period.split("/")[0];
        this.searchCriteria.autoDebitPeriod = this.autoDebitPeriod;
      } else {
        const period = formatDate(
          this.autoDebitPeriod,
          "MM/yyyy",
          this.locale
        ).toString();
        this.searchCriteria.autoDebitPeriodYear = !common.isUndefinedOrNull(
          period.split("/")[2]
        )
          ? period.split("/")[2]
          : period.split("/")[1];
        this.searchCriteria.autoDebitPeriodMonth = period.split("/")[0];
        this.searchCriteria.autoDebitPeriod = this.autoDebitPeriod;
      }
    } else {
      const validPeriod: boolean = this.autoDebitPeriod !== null;
      if (!validPeriod) {
        this.searchCriteria.autoDebitPeriodYear = "";
        this.searchCriteria.autoDebitPeriodMonth = "";
        this.searchCriteria.autoDebitPeriodDate = "";
        this.searchCriteria.autoDebitPeriod = "";
      }
    }
  };
  public export(): void {
    this.openItemListService
      .exportOpenItemListing(this.getQueryString())
      .subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            if (!this.isCitiProduct) {
              this.excelTemplate.downloadDbsOpenItemPolicies(
                dbsColumns,
                "Open Item Report",
                "Open Item Report",
                result.body.d?.results
              );
            } else {
              this.excelTemplate.downloadCitiOpenItemPolicies(
                citiColumns,
                "Open Item Report",
                "Open Item Report",
                result.body.d?.results
              );
            }
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
  public sort: SortDescriptor[] = [
    {
      field: "policyNo",
      dir: "desc",
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }
  private loadData(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort),
      total: this.listing.length,
    };
  }
  private refresh(): void {
    this.clearSelection();
    this.loading = true;
    this.openItemListService
      .searchOpenItemListing(this.getQueryString())
      .subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            this.listing = result.body.d?.results;
            this.totalListing = result.body.d?.__count;
            this.loadItems();
            this.loading = false;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
  private getQueryString(): string {
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "policyNo");
    formData.append("sort[0][dir]", "desc");

    let array = [
      [
        "effectiveDateFrom",
        "gte",
        this.searchCriteria.effectiveDateFrom
          ? this.covertDate(this.searchCriteria.effectiveDateFrom)
          : "",
      ],
      [
        "effectiveDateTo",
        "lte",
        this.searchCriteria.effectiveDateTo
          ? this.covertDate(this.searchCriteria.effectiveDateTo)
          : "",
      ],
      ["policyNo", "eq", this.searchCriteria.policyNo],
      ["invoiceNo", "eq", this.searchCriteria.invoiceNo],
      ["segments", "eq", this.searchCriteria.segments],
      ["statusIds", "eq", this.searchCriteria.statusIds],
      ["productIds", "eq", this.searchCriteria.productIds],
      ["chequeNo", "eq", this.searchCriteria.chequeNo],
      [
        "policyeffectiveDateFrom",
        "eq",
        this.searchCriteria.policyeffectiveDateFrom
          ? this.covertDate(this.searchCriteria.policyeffectiveDateFrom)
          : "",
      ],
      [
        "policyeffectiveDateTo",
        "eq",
        this.searchCriteria.policyeffectiveDateTo
          ? this.covertDate(this.searchCriteria.policyeffectiveDateTo)
          : "",
      ],
      ["autoDebitPeriodMonth", "eq", this.searchCriteria.autoDebitPeriodMonth],
      ["autoDebitPeriodYear", "eq", this.searchCriteria.autoDebitPeriodYear],
      ["autoDebitPeriodDate", "eq", this.searchCriteria.autoDebitPeriodDate],
      ["productRadio", "eq", this.searchCriteria.productRadio],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    return querystring;
  }
  private loadItems(): void {
    this.listing.forEach((entity) => {
      entity.statusDisplayText = openItemDisplayStatus[entity.statusId];
      if (
        entity.statusId === OpenItemStatus.ManualPaid ||
        entity.statusId === OpenItemStatus.Refunded
        // && paymentType === ManualPaymentType.Cheque
      ) {
        if (!common.isStringNullOrEmpty(entity.chequeNumber)) {
          const chequeInfo: string = "  (C. No: " + entity.chequeNumber + ")";
          entity.statusDisplayText += chequeInfo;
        }
      }
      entity.actionName = this.getActionName(entity);
      if (entity.actionName)
        entity.action = this.getActionByName(entity.actionName);
    });

    this.gridView = {
      data: orderBy(this.listing, this.sort), //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }
  private getActionName(entity: OpenItemListingEntity): string {
    let isPendingPayment: boolean =
      this.payableStatusList.indexOf(entity.statusId) !== -1;

    let isPendingRefund: boolean =
      this.refundableStatusList.indexOf(entity.statusId) !== -1;

    let isPendingRefundReview: boolean =
      this.refundableReviewStatusList.indexOf(entity.statusId) !== -1;
    // Manual Paid (6) & Payment by cheque (2)
    let isBounceableCheque: boolean =
      entity.statusId === 6 && entity.paymentTypeId === 2;

    const isRefundedViaPaynowOrEFT: boolean =
      this.refundedViaPaynowOrEFTStatusList.indexOf(entity.statusId) !== -1;

    if (isPendingPayment) return "PAY";
    else if (isPendingRefund) return "REFUND";
    else if (isPendingRefundReview) return "REFUND IN REVIEW";
    else if (isBounceableCheque) return "BOUNCE CHEQUE";
    else if (isRefundedViaPaynowOrEFT) return this.REFUND_DETAILS;
    else return "";
  }
  private getActionByName(actionName: string): string {
    let retValue: string;
    switch (actionName) {
      case "PAY":
        retValue = "onPayClick";
        break;
      case "REFUND":
        retValue = "onRefundClick";
        break;
      case "REFUND IN REVIEW":
        retValue = "onRefundReviewClick";
        break;
      case "BOUNCE CHEQUE":
        retValue = "onBounceChequeClick";
        break;
      default:
        retValue = "";
    }
    return retValue;
  }
  private clearSelection() {
    // Optionally, clear the selection when paging
    this.checkableRows = [];
    this.selectedRowIndexes = [];
  }
  public on_searchEffectiveDateFrom_change(value: Date): void {
    if (this.searchCriteria.effectiveDateFrom === null) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.openItemEntryDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.openItemEntryDate_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.effectiveDateTo &&
      this.searchCriteria.effectiveDateFrom &&
      this.searchCriteria.effectiveDateTo <
        this.searchCriteria.effectiveDateFrom
    ) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.openItemEntryDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.openItemEntryDate_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.openItemEntryDate_min = value;
    this.openItemEntryDate_max = maxDate;

    // open datepicker
    this.oiEffectiveDateTo.toggle(true);
  }
  public on_searchPolicyEffectiveDateFrom_change(value: Date): void {
    if (this.searchCriteria.policyeffectiveDateFrom === null) {
      this.searchCriteria.policyeffectiveDateTo = undefined;
      this.policyeffectiveDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.policyeffectiveDate_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.effectiveDateTo &&
      this.searchCriteria.effectiveDateFrom &&
      this.searchCriteria.effectiveDateTo <
        this.searchCriteria.effectiveDateFrom
    ) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.policyeffectiveDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.policyeffectiveDate_min = new Date(2099, 12, 31, 0, 0, 0);
    }
    // open datepicker
    this.oiPolicyEffectiveDateTo.toggle();
  }

  public on_AutoDebitMonthChange(val: any): void {}
  public on_AutoDebitWeekChange(val: any): void {}

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    // //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    // console.log("cell clicked");
    // //todo: this will re-route to proposal page
    // this.router.navigate(["/" + ROUTE_PROPOSAL], {
    //   queryParams: { id: dataItem.proposalId },
    // });
    this.openItemId = dataItem.openItemId;

    if (columnIndex === 0) {
      this.openItem = dataItem;
      this.openItem!.productRadio = this.searchCriteria.productRadio;

      switch (dataItem.actionName) {
        case "PAY":
          this.onPayClick();
          break;
        case "REFUND":
          this.onRefundClick(dataItem);
          break;
        case "REFUND IN REVIEW":
          this.onRefundReviewClick();
          //this.onRefundReviewClick();
          break;
        case "BOUNCE CHEQUE":
          this.onBounceChequeClick();
          break;
        case this.REFUND_DETAILS:
          this.onRefundClick(dataItem);
          break;
        default:
          return;
      }
    } else {
      this.redirectOpened = true;
    }
  }
  private onPayClick() {
    this.doManualPay = true;
  }
  private onRefundReviewClick() {
    this.doRefundPay = true;
  }
  private onBounceChequeClick(): void {
    this.doBounceCheque = true;
  }
  private onRefundClick(datas: any): void {
    this.refundOpenItemID = datas.openItemId;
    if (datas.statusId == OpenItemStatus.PendingRefundToIndividual) {
      this.doRefundEFT = true;
      // this.ConfirmationPopup(
      // "Are you sure you want to Change the status to Pending Refund to Individual in Review ?",
      //""
      //);
    }
    if (datas.statusId == OpenItemStatus.PendingRefundToBank) {
      this.ConfirmationPopup(
        "Are you sure you want to Change the status to Pending Refund to Bank in Review ?",
        ""
      );
    }

    if (datas.statusId == OpenItemStatus.RefundedViaPayNow) {
      const v1DialogRef = this.cdkDialogService.open(
        ViewPaynowDetailsComponent,
        {
          data: datas,
        }
      );
    }
    if (datas.statusId == OpenItemStatus.RefundedViaEFT) {
      const v1DialogRef = this.cdkDialogService.open(ViewEFTDetailsComponent, {
        data: datas,
      });
    }
  }

  private ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  public returnToOpenItemListing(val: any) {
    this.redirectOpened = false;
  }
  public cancelPaymentHandler(): void {
    this.doManualPay = false;
    this.openItem = undefined;
  }
  public cancelRefundHandler(): void {
    this.doRefundPay = false;
    this.openItem = undefined;
  }

  public cancelEFTHandler(): void {
    this.doRefundEFT = false;
    this.openItem = undefined;
  }

  public cancelBounceChequeHandler(): void {
    this.doBounceCheque = false;
    this.openItem = undefined;
  }
  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      const postData = { openItemId: this.refundOpenItemID };
      this.openItemListService.doRefundReview(postData).subscribe(
        (response: any) => {
          // refresh grid
          this.refresh();

          this.openStatusPopup("Status Updated Succesfully");
        },
        (err: any) => {
          this.refresh();
          this.openStatusPopup("Status Updated Failed");
        }
      );
    }
  }
  public makePaymentHandler(manualPaymentEntity: ManualPaymentEntity): void {
    this.doManualPay = false;
    this.openItemListService.doManualPay(manualPaymentEntity).subscribe(
      (response: any) => {
        this.refresh();
        this.openStatusPopup("Payment made Succesfully");
      },
      (err: any) => {
        this.refresh();
        this.openStatusPopup("Payment failed");
      }
    );
  }

  public doRefundHandler(refundPaymentEntity: RefundPaymentEntity): void {
    this.doRefundPay = false;
    this.openItemListService.doRefund(refundPaymentEntity).subscribe(
      (response: any) => {
        this.refresh();
        if (common.isUndefinedOrNull(refundPaymentEntity.paymentMethod)) {
          this.openStatusPopup("Payment refunded successfully");
        }
      },
      (err: any) => {
        this.refresh();
        this.openStatusPopup("Payment refunded failed");
      }
    );
  }

  public doEFTHandler(refundPaymentEntity: RefundPaymentEntity): void {
    this.doRefundPay = false;
    const postData = {
      openItemId: this.refundOpenItemID,
      changestatus: refundPaymentEntity.changestatus,
    };
    this.openItemListService.doRefundReview(postData).subscribe(
      (response: any) => {
        // refresh grid
        // this.refresh();
        if (refundPaymentEntity.changestatus < "25") {
          this.openItemListService.doRefund(refundPaymentEntity).subscribe(
            (response: any) => {
              this.refresh();
              this.cancelEFTHandler();
              this.openStatusPopup("Status Updated Succesfully");
            },
            (err: any) => {
              this.refresh();
              this.openStatusPopup("Status Updated Failed");
            }
          );
        } else {
          this.refresh();
          this.cancelEFTHandler();
          this.openStatusPopup("Status Updated Succesfully");
        }
        //this.openStatusPopup("Status Updated Succesfully");
      },
      (err: any) => {}
    );
  }

  public bounceChequeHandler(manualPaymentEntity: ManualPaymentEntity): void {
    this.doBounceCheque = false;
    this.openItemListService.doBounceCheque(manualPaymentEntity).subscribe(
      (response: any) => {
        this.refresh();
        this.openStatusPopup("Cheque bounced successfully");
      },
      (err: any) => {
        this.refresh();
        this.openStatusPopup("Cheque bounced failed");
      }
    );
  }
  private covertDate(date: Date): string {
    const answer = new Date(date);
    const year = answer.getFullYear();
    const month = answer.getMonth() + 1;
    const day = answer.getDate();
    return `${year}-${month}-${day}`;
  }
  private openStatusPopup(msg: string) {
    const data = {
      message: msg,
    };
    const v1DialogRef = this.cdkDialogService.open(
      MdInformationMessageComponent,
      {
        data: data,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }
}
const dbsColumns: string[] = [
  "Meridian Sequence No",
  "Policy Certificate No",
  "Master Policy No",
  "Policy No",
  "Entry Date",
  "Total Premium",
  "GST",
  "Status",
  "Invoice No",
  "Saved By",
  "policy Holder Name",
  "Correspondence Address",
  "Inactive Address Changed",
  "Gross Premium",
  "BUPC",
  "Bank Reference",
  "Insured Name",
  "Effective Date",
  "Expiry Date",
  "Sum Insured",
  "Risk Address",
  "Insured NRIC",
  "Mobile Number",
  "Additional Insured",
  "AD-Month",
  "Cancellation Adjustment",
];
const citiColumns: string[] = [
  "Meridian Sequence No",
  "Policy Certificate No",
  "Master Policy No",
  "Policy No",
  "Entry Date",
  "Net Premium",
  "GST",
  "Premium To Be Collected From Customer",
  "Net Comm Offset with Discount",
  "Status",
  "Invoice No",
  "Saved By",
  "policy Holder Name",
  "Correspondence Address",
  "Inactive Address Changed",
  "Customer Type",
  "Collateral No",
  "Insured Name",
  "Effective Date",
  "Expiry Date",
  "Sum Insured",
  "Risk Address",
  "Insured NRIC",
  "Mobile Number",
  "Additional Insured",
  "AD-Week",
  "Cancellation Adjustment",
];
