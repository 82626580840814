import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import {
  RefundPaymentEntity,
  OpenItemEntity,
  RefundPaynowEntity,
} from "src/app/components/accounting/open-item/models/open-item-list.model";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { RefundPaymentType } from "src/app/framework/enum.shared";
import { RefundStatus } from "src/app/framework/enum.shared";
import { OpenItemStatus } from "src/app/finance/finance-enums";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { common } from "src/app/framework/utils/common";
//import { common } from "../../../framework/utils/common";
import { MdInformationMessageComponent } from "src/app/components/shared/partials/md-information-message/md-information-message.component";

import { OpenItemListService } from "src/app/services/open-item-list.service";

//import { PaynowEditorComponent } from "./paynow-editor/paynow-editor.component";

@Component({
  selector: "app-eft-refund-pay-form",
  templateUrl: "./do-eft.component.html",
  styleUrls: ["./do-eft.component.scss"],
})
export class DoEFTComponent implements OnInit {
  public refundPayment = new RefundPaymentEntity();
  public openItemId!: string;
  public openItemEntity = new OpenItemEntity();
  public totalPremium: any;
  public active = false;
  public opened: boolean = false;
  public isHidePaymentMethodDDL: boolean = false;
  public isHidMobileNumber: boolean = false;
  public isHideEFT: boolean = false;
  public isHideOtherBanktext: boolean = false;
  productRadio: string | undefined;
  refundType!: RefundPaymentType;
  refundTypeDisplayText!: string;

  paymentrefundToInd_UserEnteredbankcode!: number;

  PaymentmethodOptions: Array<{ text: string; value: string }> = [
    {
      text: "PayNow NRIC",
      value: "2",
    },
    {
      text: "PayNow Mobile Number",
      value: "3",
    },
    {
      text: "PayNow UEN",
      value: "1",
    },
    {
      text: "EFT",
      value: "4",
    },
    {
      text: "Cheque",
      value: "5",
    },
  ];

  public defaultPaymentmethod: { text: string; value: string } = {
    text: "- Please select status -",
    value: "",
  };

  ChangetoStatusOptions: Array<{ text: string; value: string }> = [
    {
      text: RefundStatus.PendingRefundToIndividualInReview,
      value: "21",
    },
    {
      text: RefundStatus.PendingRefundToIndividualInReviewAdHoc,
      value: "25",
    },
    {
      text: RefundStatus.PendingRefundToIndividualInReviewNA,
      value: "26",
    },
  ];

  public defaultChangetoStatusItem: { text: string; value: string } = {
    text: "- Please select status -",
    value: "",
  };

  bankNameOptions: Array<{ text: string; value: string }> = [
    //{ text: "All", value: "" },
    { text: "UOB", value: "1" },
    { text: "DBS", value: "2" },
    { text: "POSBank", value: "3" },
    { text: "OCBC", value: "4" },
    { text: "HSBC Corporate", value: "5" },
    { text: "HSBC Personal", value: "6" },
    { text: "SBI", value: "7" },
    { text: "Standard Chartered", value: "8" },
    { text: "Citibank", value: "9" },

    { text: "Other", value: "10" },
  ];

  public defaultbankNameItem: { text: string; value: string } = {
    text: "--Select bank--",
    value: "",
  };

  public refundPaynow = new RefundPaynowEntity();
  //const postData
  //@ViewChild("childPaynowComponent", { static: false })
  // childPaynowComponent!: PaynowEditorComponent;
  @Input()
  public set model(openItemEntity: any) {
    this.refundPayment.openItemId = openItemEntity.openItemId;
    this.productRadio = openItemEntity.productRadio;
  }
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() doRefund: EventEmitter<RefundPaymentEntity> = new EventEmitter();
  @Output() doEFT: EventEmitter<RefundPaymentEntity> = new EventEmitter();
  //validation
  invalidRefundType_PendingRefundToIndividualInReview = false;
  invalidRequiredField = false;
  //invalidPayerName = false;
  //invalidCIB = false;
  // invalidCN = false;
  // invalidRemark = false;
  invalidMobileNumber = false;
  invalidChangetoStatus = false;
  invalidbankAccountNumber = false;
  invalidbankName = false;
  invalidbranch = false;
  invalidChangetoStatusType = false;
  invalidPaymentmethod = false;
  invalidlabelheader = false;
  invalidotherBankName = false;
  invalidpaymentrefundToInd_Bank = false;
  invalidBankCode = false;
  invalidBranch = false;
  invalidBranchCode = false;

  constructor(
    private cdkDialogService: CdkDialogService,
    private openItemListService: OpenItemListService
  ) {}

  ngOnInit(): void {
    this.getOpenItemDetails();
  }
  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }
  public on_paymentrefundToInd_ChangetoStatus_change() {
    this.invalidRequiredField = false;
    this.isHidePaymentMethodDDL = this.refundPayment.changestatus == "21";
  }

  public on_PaymentMethod_change() {
    this.invalidRequiredField = false;
    this.isHidMobileNumber = this.refundPayment.paymentMethod == 3;
    this.isHideEFT = this.refundPayment.paymentMethod == 4;
  }
  public on_ChangeBankCode_change() {
    this.invalidRequiredField = false;
    this.isHideOtherBanktext = this.refundPayment.bankcode == 10;
  }
  private getOpenItemDetails() {
    this.openItemListService
      .getOpenItem(this.refundPayment.openItemId)
      .subscribe((response: OpenItemEntity) => {
        const openItem: OpenItemEntity = response;
        this.active = openItem !== undefined;
        this.openItemEntity = openItem;

        this.refundType =
          openItem.statusId === OpenItemStatus.PendingRefundToBankInReview
            ? RefundPaymentType.OnlineTransfer
            : openItem.statusId ===
              OpenItemStatus.PendingRefundToIndividualInReview
            ? RefundPaymentType.Cheque
            : RefundPaymentType.Undefined;

        const refundTypeDisplayText: string =
          proposalCommon.translateRefundPaymentType(this.refundType);
        this.refundTypeDisplayText = refundTypeDisplayText;

        this.refundPayment.refundPaymentType = this.refundType; //will be updated to PayNow if chosen

        //this.addPayNowOption();
      });
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  public onSave(e: PointerEvent): void {
    this.invalidRequiredField = false;

    let isValid: boolean = true;
    //if (this.refundType === 1) {

    isValid = this.validateGeneralMandatoryFields();

    if (isValid) {
      isValid =
        this.refundPayment.paymentMethod == 3
          ? this.validatePaynowMobileNumberMandatoryFields()
          : this.refundPayment.paymentMethod == 4
          ? this.validateEFTMandatoryFields()
          : true;

      if (isValid) {
        if (
          this.refundPayment.paymentMethod == 1 ||
          this.refundPayment.paymentMethod == 2 ||
          this.refundPayment.paymentMethod == 3
        ) {
          this.mapPaynowMobilenumberToRefundPaymentEntity();
        } else if (this.refundPayment.paymentMethod == 4) {
          this.mapEFTToRefundPaymentEntity();
        } else {
          this.mapChequeToRefundPaymentEntity();
        }
      }
    }
    //}

    if (isValid) {
      this.ConfirmationPopup(
        "Are you sure you want to change the status to " +
          this.ChangetoStatusOptions.filter(
            (x) => x.value == this.refundPayment.changestatus?.toString()
          )[0].text +
          " ?",
        ""
      );
    } else {
      this.invalidRequiredField = true;
      e.preventDefault();
    }
  }

  private mapChequeToRefundPaymentEntity() {
    this.refundPayment.refundPaymentType = RefundPaymentType.Cheque;
  }
  private mapPaynowMobilenumberToRefundPaymentEntity() {
    this.refundPayment.refundPaymentType = RefundPaymentType.Paynow;
  }
  private mapEFTToRefundPaymentEntity() {
    this.refundPayment.refundPaymentType = RefundPaymentType.EFT;

    if (this.refundPayment.bankcode?.toString() != "10") {
      this.refundPayment.bankName = this.bankNameOptions.filter(
        (x) => x.value == this.refundPayment.bankcode?.toString()
      )[0].text;
    }
    this.refundPayment.bankcode = this.paymentrefundToInd_UserEnteredbankcode;
  }

  private validateGeneralMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    if (
      this.refundPayment.changestatus != "25" &&
      this.refundPayment.changestatus != "26"
    ) {
      if (
        this.refundPayment.changestatus == undefined ||
        this.refundPayment.changestatus == "" ||
        this.refundPayment.paymentMethod == undefined ||
        this.refundPayment.paymentMethod.toString() == ""
      ) {
        hasError = true;
      }
    }

    return !hasError;
  }

  private validatePaynowMobileNumberMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    var phoneno = /^\+?[0-9]+$/;
    if (this.refundPayment.paynowMobileNumber == undefined) {
      hasError = true;
      this.invalidMobileNumber = true;
    } else if (this.refundPayment.paynowMobileNumber.length != 8) {
      hasError = true;
      this.invalidMobileNumber = true;
    } else if (!this.refundPayment.paynowMobileNumber!.match(phoneno)) {
      hasError = true;
      this.invalidMobileNumber = true;
    }

    return !hasError;
  }

  private validateEFTMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    if (this.refundPayment.bankAccountNumber == undefined) {
      hasError = true;
      this.invalidbankAccountNumber = true;
    } else {
      this.invalidbankAccountNumber = false;
    }
    if (this.refundPayment.bankcode == undefined) {
      hasError = true;
      this.invalidbankName = true;
    } else if (this.refundPayment.bankcode.toString() == "") {
      hasError = true;
      this.invalidbankName = true;
    } else {
      this.invalidbankName = false;
    }

    if (this.refundPayment.bankcode != undefined) {
      if (this.refundPayment.bankcode?.toString() == "10") {
        if (this.refundPayment.bankName == undefined) {
          hasError = true;
          this.invalidotherBankName = true;
        } else {
          this.invalidotherBankName = false;
        }
      }
    }

    if (this.paymentrefundToInd_UserEnteredbankcode == undefined) {
      hasError = true;
      this.invalidBankCode = true;
    } else if (
      this.paymentrefundToInd_UserEnteredbankcode.toString().length > 4
    ) {
      hasError = true;
      this.invalidBankCode = true;
    } else {
      this.invalidBankCode = false;
    }
    if (this.refundPayment.branchName == undefined) {
      hasError = true;
      this.invalidBranch = true;
    } else {
      this.invalidBranch = false;
    }
    if (this.refundPayment.branchCode == undefined) {
      hasError = true;
      this.invalidBranchCode = true;
    } else if (this.refundPayment.branchCode.toString().length > 3) {
      hasError = true;
      this.invalidBankCode = true;
    } else {
      this.invalidBranchCode = false;
    }

    return !hasError;
  }

  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      // const postData = {
      // openItemId: this.refundPayment.openItemId,
      //sentOpenItemStatusIDFromUI: this.refundPayment.changestatus,
      //};
      this.doEFT.emit(this.refundPayment);
      // if (this.refundPayment.changestatus < "25") {
      // this.doRefund.emit(this.refundPayment);
      //}
      //this.openItemListService.doRefundReview(postData).subscribe(
      // (response: any) => {
      // refresh grid

      //  this.openStatusPopup("Status Updated Succesfully");
      // },
      //(err: any) => {}
      //);
    }
  }

  private openStatusPopup(msg: string) {
    const data = {
      message: msg,
    };
    const v1DialogRef = this.cdkDialogService.open(
      MdInformationMessageComponent,
      {
        data: data,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }
}
